import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; 
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'yellow',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='border__b  mb-5 rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://www.informationng.com/wp-content/uploads/2017/11/toni-kelly-new-zealand-1148841.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Iris Rosso</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='px-2'>
            <p className='text-[whitesmoke] text-center pt-[10%] pb-5 '>
            For years, I've been dating a man named Jonah Tennison on Facebook. He promised to spend the rest of his life with me, and I loved him deeply. Out of love, I lent him $50,000. However, he suddenly blocked me and disappeared. It was devastating. Thankfully, I heard about  Richardservodio Btcasset Recover. Their team, with their speedy and efficient service, restored my money. I owe them a tremendous debt of gratitude.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-3'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-5'>Tallahassee, FL USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md '>
          <div className='flex justify-center pt-5 mb-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://res.cloudinary.com/graham-media-group/image/upload/f_auto/q_auto/c_scale,w_400/v1/production/public/PN6FYUGPCBEC7PKZH57W7RDDCE.jpg?_a=AJFJtWIA' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
          <p className="text-center font-bold pt-3 text-[#c0c2cd]">Kaelen Finn</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className=''>
            <p className='text-[whitesmoke] pt-5 text-center  pb-5'>
            I met a girl on Tinder who promised to have a great time with me. She seemed genuine, but it turned out she scammed me and took about $20,000. She never showed up. Desperate for help, I stumbled upon recommendations for  Richardservodio Btcasset Recover. This amazing team of experts restored my money, and I am 100% grateful to them.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[50px]'>
            <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-[30px]'>Tampa, FL USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.pinimg.com/736x/ba/de/98/bade9840e5a72c3049d223efb97d94d8.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Isabella Wrigh</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='p-1'>
            <p className='text-[whitesmoke] text-center pt-[13%] pb-2'>
            I thought I had found true love online until my so-called lover asked me to invest in a shady business. Trustingly, I invested $76,000, only to be scammed and left behind. Fortunately, this incredible team at  Richardservodio Btcasset Recover came to my rescue. They restored my money without wasting any time. 
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[15%]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-[2rem]'>Alpharetta, ATL USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://media-cdn.tripadvisor.com/media/photo-p/0e/6a/92/a4/a-selfie-in-room-worth.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
          <p className="text-center font-bold pt-3 text-[#c0c2cd]">Isla Montgomery</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='py-1'>
            <p className='text-[whitesmoke] text-center pt-[5%] pb-5'>
            I was deeply in love with someone I met online, and we planned to meet in person. To make our dream come true, I sent her $45,000 to cover her travel expenses. However, she vanished into thin air, and my heart and money were broken. In my despair, I reached out to  Richardservodio Btcasset Recover, hoping for a miracle. They worked tirelessly, and with their expertise, they recovered my funds. I can't express how thankful I am.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[5%]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-[1%]'>Wawa Ontario, Canada 🇨🇦</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
              <img src='https://preview.redd.it/i-am-laying-in-bed-in-a-hotel-room-v0-a6vbyaikwqua1.jpg?width=640&crop=smart&auto=webp&s=156b436a951edbe06a4691da34cc5e9c86e27d1a' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
          <p className="text-center font-bold pt-3 text-[#c0c2cd]">Calista Nightshade</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[whitesmoke] text-center pt-5 pb-2'>
            I met the love of my life on a dating app, and we connected instantly. However, she convinced me to invest $30,000 in a supposed 'business opportunity.' It turned out to be a romance scam, and my trust was shattered. Fortunately, I discovered  Richardservodio Btcasset Recover, and they helped me reclaim my money and my belief in true love. Their dedication and support meant the world to me. - Testified by Ethan Mitchell, Toronto, Canada, 2 months ago.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Memphis, USA  🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
     
    </Swiper>
  );
};

export default MySwiper;
