import React from 'react';
import Navbars from '../hooks/Navbars';
import { GoDash } from "react-icons/go";
import CryptoReview from "../hooks/CryptoReview";
import Footer from "../hooks/Footer";
import { Link } from "react-router-dom";

const Crypto = () => {
  const CustomeIcon = {
    fontSize: "25px",
    '--custom-icon-color': '#0e6af4'
}
  return (
   <>
    <div className=''>
      <Navbars/>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl'>
      <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[1%]'>
        <div className='bg-gren-600 h-fit'>
            <div className='xl:pt-5 pt-0'>
              <img src="pexels-worldspectrum-1097946-1891x2048.jpg" alt="" />
            </div>
        </div>
          <div className='bg-gren-500 h-fit xl:pl-5 pl-1'>
            <div className='flex'>
                <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4] uppercase'>Services</span>
            </div>

            <div>
                <p className='font-bold text-[30px] xl:text-[40px] text-[black] uppercase'>We specialize in cryptocurrency recovery.</p>
            </div>

            <div className='pt-5'>
              <p className='font-bold text-[#0e6af4]'>The Best Solutions for Our Customers</p>
            </div>

            <div className='pt-5'>
              <p>In recent times, Bitcoin scams have proliferated, exploiting the cryptocurrency's prominence and enticing investors with its impressive performance. Unfortunately, this attractiveness has also drawn the attention of fraudsters who deceive unsuspecting victims under the guise of leading crypto exchanges. These scams, whether in Bitcoin trading or other financial sectors like binary options and forex trading, have left many individuals financially compromised.</p>
            </div>
            <div className='pt-6'>
              <p>
              At ApexTechie, we specialize in restoring lost cryptocurrency and combating cybercrime. Our team of experts is dedicated to assisting victims of such scams, providing a shared intelligence and asset tracing service aimed at recovering stolen cryptocurrencies. With a wealth of experience and a commitment to excellence, ApexTechie connects victims with professional resources to navigate the complex landscape of cybercrime.
              </p>
            </div>
            <div className='pt-5'>
              <p>
              If you've fallen victim to a crypto scam or any other financial fraud, our specialists are here to support you. Thousands have entrusted us with their recovery efforts, and we take pride in delivering optimal results for our customers.
              </p>
            </div>
          </div>
      </div>
    </div>
    <div className='bg-[#0E6AF4] w-full h-fit p-4 overflow-hidden'>
        <div className='mx-auto md:max-w-4xl overflow-hidden'>
            <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden'>
                <div className=''>
                 <div className='flex'>
                 <img src="shield.png" alt="" className='w-[64px]'/>
                  <div className='pl-5'>
                    <p className='font-bold text-white pt-3'>Stop worrying, our recovery process is guaranteed.</p>
                  </div>
                 </div>
                </div>
                <div>
                 <div className='xl:ml-[15%] ml-1'>
                   <a href="/contact">
                    <button className='bg-[#1d1829] p-4 text-white btn__touch1'>GET IN TOUCH</button>
                   </a>
                 </div>
                </div>
            </div>
        </div>
    </div>
          <div className='bg-[#1d1829] h-fit'>
             <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Top Testimonies</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Top Reviews on Cryptocurrency Recovery</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        At ApexTechie, we take immense pride in the outstanding reviews garnered over our journey. These testimonials underscore our steadfast dedication to safeguarding our clients' financial interests. Through our expertise, we've effectively retrieved funds lost to diverse scams, earning the appreciation and trust of numerous individuals. 
                        </p>
                    </div>
                </div>

                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden pb-10'>
                  <CryptoReview/>
                </div>
            </div>

        <div className=''>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Why Choose Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[black]'>Why Choose Richardservodio Btcasset Recover For Crypto Recovery</p>
                        </div>
                        <div className='pt-5 pr-3'>
                          <p>Clients choose ApexTechie for crypto recovery due to our proven track record in retrieving lost funds from various scams. With our expertise and dedication, we ensure swift and reliable recovery, earning trust and appreciation from countless individuals.</p>
                        </div>

                        <div className='flex justify-center pt-5'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>All Crypto Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Fake Bank Investment Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Fake Crypto Investment Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Hacked Crypto Wallet Recovery</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Investment Scam Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Fake Broker Investmet Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Crypto Wallet Tracing</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Fake Broker site Investmet Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Crypto Wallet Tracing</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0e6af4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                             </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                      <div className='shadow-md'>
                        <img src="pexels-kevin-ku-577585-1-2048x1536.jpg" alt=""  className='rounded-md shadow-md img__border'/>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>

      <Footer/>
   </>
  )
}

export default Crypto