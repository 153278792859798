import React from 'react';
import Navbars from '../hooks/Navbars';
import { GoDash } from "react-icons/go";
import Footer from "../hooks/Footer";
import { Link } from "react-router-dom";
import PhoneReview from '../hooks/PhoneReview';


const Crypto = () => {
  const CustomeIcon = {
    fontSize: "25px",
    '--custom-icon-color': '#0e6af4'
}
  return (
   <>
    <div className=''>
      <Navbars/>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl'>
      <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[1%]'>
        <div className='bg-gren-600 h-fit'>
            <div className='xl:pt-10 pt-0'>
              <img src="https://img.freepik.com/premium-photo/secure-banking-cellphone_207634-2084.jpg?w=1060" alt="" />
            </div>
        </div>
          <div className='bg-gren-500 h-fit xl:pl-5 pl-1'>
            <div className='flex'>
                <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4] uppercase'>Services</span>
            </div>

            <div>
                <p className='font-bold text-[30px] xl:text-[40px] text-[black] uppercase'>Social Media Hack/Recovery</p>
            </div>

            <div className='pt-5'>
              <p className='font-bold text-[#0e6af4]'>Unlocking Your Smartphone: Understanding the Essentials and Ensuring Safety</p>
            </div>

            <div className='pt-5'>
              <p>In the quest for a new smartphone or when faced with a locked device, unlocking becomes paramount. But what exactly is an unlocked phone, and how can you determine its status? Moreover, is it safe to use? These pressing inquiries are addressed by our expert team at Richardservodio Btcasset Recover.</p>
            </div>
            <div className='pt-6'>
              <p>
              In today's world, the demand for cellphone access is skyrocketing. Concerned parents seek insight into their children's phone usage, while partners harbor suspicions and seek reassurance about their loved ones' activities.
              </p>
            </div>
            <div className='pt-5'>
              <p>
              At Richardservodio Btcasset Recover, we offer specialized services to meet these needs. Our professionals are adept at accessing the day-to-day activities of any target, regardless of their location. Trust us to provide the solutions you seek.
              </p>
            </div>
          </div>
      </div>
    </div>
    <div className='bg-[#0E6AF4] w-full h-fit p-4 overflow-hidden'>
        <div className='mx-auto md:max-w-4xl overflow-hidden'>
            <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden'>
                <div className='overflow-hidden'>
                 <div className='flex'>
                 <img src="shield.png" alt="" className='w-[64px]'/>
                  <div className='pl-5'>
                    <p className='font-bold text-white pt-3'>Stop worrying, our recovery process is guaranteed.</p>
                  </div>
                 </div>
                </div>
                <div>
                 <div className='xl:ml-[15%] ml-1'>
                   <a href="/contact">
                    <button className='bg-[#1d1829] p-4 text-white btn__touch1'>GET IN TOUCH</button>
                   </a>
                 </div>
                </div>
            </div>
        </div>
    </div>
          <div className='bg-[#1d1829] h-fit'>
             <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Top Testimonies</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Top Reviews on Phone Spy & Unlock.</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        At Richardservodio Btcasset Recover, we've earned top reviews for our exceptional expertise in phone spy and unlock services. Our dedicated team ensures seamless access to target devices, garnering praise from satisfied clients worldwide. Trust us for reliable solutions and unmatched professionalism.
                        </p>
                      </div>
                      </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden pb-10'>
                  <PhoneReview/>
                </div>
            </div>

        <div className=''>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Why Choose Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[black]'>Why Choose Richardservodio Btcasset Recover For Phone Spy & Unlock.</p>
                        </div>
                        <div className='pt-5 pr-3 pb-5 xl:pb-0'>
                          <p>When it comes to phone spy and unlock services, Richardservodio Btcasset Recover stands out as the trusted authority. With our proven track record of reliability and expertise, we provide seamless access to target devices. Choose Richardservodio Btcasset Recover for unparalleled professionalism and guaranteed results.</p>
                        </div>

                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iMac, Mac Book & iPad Unlock/Hack</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iCloud Unlock</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Phone Tracking</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Android Hack/Unlock</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iOS Hack/Unlock</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Laptop & Phone Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Laptop & Phone Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>iOS Hack/Unlock</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                              
                        </div>
                            
                    </div>
                    <div className='bg-gren-500 h-fit'>
                      <div className='shadow-md xl:mt-10 mt-0'>
                        <img src="https://img.freepik.com/free-photo/programer-sitting-desk-with-multiple-screens-running-code-talking-with-colleague-about-artificial-intelligence-algorithm-software-developers-doing-innovative-artificial-intelligence-project_482257-40606.jpg?t=st=1715562618~exp=1715566218~hmac=7c35cf714c45204c6d0713c6f9ef6c960ec33c5ab7e87664fb3da9b637db8b27&w=1800" alt=""  className='rounded-md shadow-md img__border'/>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>

      <Footer/>
   </>
  )
}

export default Crypto;