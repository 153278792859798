import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; 
import { Avatar } from '@mui/material';

const MySwiper = () => {
  

  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
            <div className='border__b rounded-md '>
            <div className=" h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                       
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://www.midliferambler.com/wp-content/uploads/2020/07/selfie-tip1-after.jpg' alt=""/>
                            </Avatar>
                      
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Lavinia Gallagher</p>
                    </div>
                   

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I had suspicions about my apprentice for a while now, suspecting that they were embezzling money from my business. To confirm my doubts, I came across a recommendation for Richardservodio Btcasset Recover. They worked their magic on my apprentice's phone, granting me access to their activities. What I discovered was shocking – my worker had siphoned off over $70,000+ from my business. Thanks to  Richardservodio Btcasset Recover, the truth was uncovered
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'>Vermont • USA •  4 Days ago</p>
                    </div>
                </div>

                </div>   
           </div> 
            </div>
                 
          
      </SwiperSlide>

      <SwiperSlide>
       <div className=" h-fit overflow-hidden  ml-2 mr-2 border__b rounded-md ">
       <div className=" h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://s.yimg.com/ny/api/res/1.2/xf8iX0vrLG_hRHBiQCMwVA--/YXBwaWQ9aGlnaGxhbmRlcjt3PTY0MDtoPTgwMA--/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2019-11/a1191270-1314-11ea-9fcb-96817ed7091e' alt=""/>
                            </Avatar>
                     
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Rosalind Mercer</p>
                    </div>
                   
                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    For years, my iCloud had been disabled, and I desperately tried to unlock it. In the process, I fell victim to a scam, losing $39,000. It was a nightmare. However, my luck turned around when I discovered  Richardservodio Btcasset Recover. They not only unlocked my iCloud within minutes but also managed to recover my lost $17,000. This team is simply the best, and I wholeheartedly recommend them!
                    </p>


                    <div className='pl-2 pb-3 pt-[5%]'>
                        <p className='text-[#b0b0b0] text-center '> Montana • USA • 12 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__b rounded-md">
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                      
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://s3-media1.fl.yelpcdn.com/photo/3_XRHrLZ5gbmYccoxc5BfQ/o.jpg' alt=""/>
                            </Avatar>
                      
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Evangeline Stanton</p>
                    </div>
                    

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    My phone was stolen, and it held invaluable information that I couldn't bear to lose. That's when I heard about  Richardservodio Btcasset Recover. They assured me that they could help, and they certainly did. With their expertise, they tracked my stolen phone and ensured that all my valuable files remained intact. Thanks to  Richardservodio Btcasset Recover, I didn't lose a single piece of crucial data. Their perfect services saved the day
                    </p>


                    <div className='pl-2 pb-3 pt-4'>
                        <p className='text-[#b0b0b0] text-center'> Wyoming • USA• 2 weeks ago</p>
                    </div>
                </div>

                </div>   
           </div> 
    </div>
      </SwiperSlide>

      <SwiperSlide>
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__b rounded-md">
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                       
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://www.lebanoninapicture.com/images/pages/1dfa5d26-2854-41c0-aac3-402b17ac1948/selfie-selfieday-smile-beard-men-lovelife-insta-11-22-2017-6-56-15-pm-l.jpg' alt=""/>
                            </Avatar>
                        
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Jaxon Turner</p>
                    </div>
                    

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I had a device with a forgotten passcode, and I was on the brink of giving up hope. I had valuable photos and documents on that device that I couldn't afford to lose. That's when I heard about  Richardservodio Btcasset Recover. They not only helped me unlock my device but also ensured that all my precious data remained intact. Their professionalism and commitment to customer satisfaction are truly commendable. I can't thank them enough for their incredible service.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'>South Australia • Australia • 1 Hour Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__b rounded-md">
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__x">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQewlnu3DEn-BGBc0B2zp8Uq4SuqwUPejlsQqiTRefo2nEW0ucFS9R_PW7Z2u5Q1bbdrcw&usqp=CAU' alt=""/>
                            </Avatar>
                       
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Finnian Mitchell</p>
                    </div>
                   

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I was locked out of my phone for weeks, and it was driving me crazy. I couldn't access my important data or even make calls. I was desperate, but then I found  Richardservodio Btcasset Recover. Within no time, they had my phone unlocked and working perfectly again. It was like a miracle! I can't thank them enough for their quick and efficient service.
                    </p>


                    <div className='pl-2 pb-3 pt-10'>
                        <p className='text-[#b0b0b0] text-center'> Scotland • United Kingdom • 13 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
           </div>
      </SwiperSlide>
     
    </Swiper>
  );
};

export default MySwiper;
