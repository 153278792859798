import React from 'react';
import Navbars from '../hooks/Navbars';
import { GoDash } from "react-icons/go";
import Footer from "../hooks/Footer";
import { Link } from "react-router-dom";
import BlackmailReview from '../hooks/BlackmailReview';

const Crypto = () => {
  const CustomeIcon = {
    fontSize: "25px",
    '--custom-icon-color': '#0e6af4'
}
  return (
   <>
    <div className=''>
      <Navbars/>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl'>
      <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[1%]'>
        <div className='bg-gren-600 h-fit'>
            <div className='xl:pt-5 pt-0'>
              <img src="Screenshot 2024-05-11 at 2.13.08 AM.png" alt="" />
            </div>
        </div>
          <div className='bg-gren-500 h-fit xl:pl-5 pl-1'>
            <div className='flex'>
                <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4] uppercase'>Services</span>
            </div>

            <div>
                <p className='font-bold text-[30px] xl:text-[40px] text-[black] uppercase'>Help from blackmail & extortion</p>
            </div>

            <div className='pt-5'>
              <p className='font-bold text-[#0e6af4]'>Richardservodio Btcasset Recover, Your Trusted Ally Against Blackmail and Extortion</p>
            </div>

            <div className='pt-5'>
              <p>Blackmail is the action, treated as a criminal offence, of demanding payment or another benefit from someone in return for not revealing compromising or damaging information about them. Though blackmail can happen in a range of very different circumstances, in most cases it can be classified into three broad types: extortion, coercion, and commercial pressure. Extortion usually involves some sort of monetary transaction in exchange for keeping certain information private.</p>
            </div>
            <div className='pt-6'>
              <p>
              At Richardservodio Btcasset Recover, we specialize in aiding victims of online blackmail, sextortion, online threats, extortion, coercion, and commercial pressure. Our dedicated team is here to provide expert assistance and support in navigating through such challenging situations.
              </p>
            </div>
            <div className='pt-5'>
              <p>
              Please remember, if you or someone you know is facing such threats, it’s important to reach out to local authorities or professional services that specialize in dealing with such situations. It’s crucial to act promptly and safeguard personal information at all times. Stay secure!
              </p>
            </div>
          </div>
      </div>
    </div>
    <div className='bg-[#0E6AF4] w-full h-fit p-4 overflow-hidden'>
        <div className='mx-auto md:max-w-4xl overflow-hidden'>
            <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden'>
                <div className='overflow-hidden'>
                 <div className='flex'>
                 <img src="shield.png" alt="" className='w-[64px]'/>
                  <div className='pl-5'>
                    <p className='font-bold text-white pt-3'>Stop worrying, our recovery process is guaranteed.</p>
                  </div>
                 </div>
                </div>
                <div>
                 <div className='xl:ml-[15%] ml-1'>
                   <a href="/contact">
                    <button className='bg-[#1d1829] p-4 text-white btn__touch1'>GET IN TOUCH</button>
                   </a>
                 </div>
                </div>
            </div>
        </div>
    </div>
          <div className='bg-[#1d1829] h-fit'>
             <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Top Testimonies</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Top Reviews on Blackmail & Extortion</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        We're proud to showcase our outstanding track record and top-notch client feedback for our expertise in resolving blackmail and extortion matters. With a dedicated team committed to professionalism and discretion, trust us to navigate these challenging situations with skill and integrity, ensuring optimal outcomes for our valued clients.
                        </p>
                      </div>
                      </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden pb-10'>
                  <BlackmailReview/>
                </div>
            </div>

        <div className=''>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Why Choose Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[black]'>Why Choose Richardservodio Btcasset Recover For Help from Blackmail & Extortion</p>
                        </div>
                        <div className='pt-5 pr-3 pb-5 xl:pb-0'>
                          <p>Richardservodio Btcasset Recover stands as the pinnacle choice for navigating the complexities of blackmail and extortion. With our seasoned expertise and steadfast commitment to resolution, trust us to safeguard your interests and guide you towards a favorable outcome.</p>
                        </div>

                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Money Recovery From Blackmail/Extortion</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Online Threats</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sextortion Help</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Extortion help</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Blackmail</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Text Messages Hack/Spy</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Help from Blackmail</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                      <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                            
                        </div>
                            
                    </div>
                    <div className='bg-gren-500 h-fit'>
                      <div className='shadow-md xl:mt-10 mt-0'>
                        <img src="https://www.imt.ch/files/Inhalte/Bilder/Seiteninhalte/Expertise/IMT_HR_software_2023_8_HIGH.jpg" alt=""  className='rounded-md shadow-md img__border'/>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>

      <Footer/>
   </>
  )
}

export default Crypto;