import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { GoDash } from "react-icons/go";
import { useRef, useEffect } from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Review from '../hooks/Review';
import Footer from "../hooks/Footer";
import { Link } from 'react-router-dom';


const Home = () => {
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };

    useEffect(() => {
        // This effect will run after the component mounts
        const script = document.createElement('script');
        script.src = 'https://static-bundles.visme.co/forms/vismeforms-embed.js';
        script.async = true;
        document.body.appendChild(script);
    
        // Clean up function to remove the script when the component unmounts
        return () => {
          document.body.removeChild(script);
        };
      }, []);
  return (
    <>
      
       <div className='home__img'>
            <div>
                <Navbar/>
            </div>


            <div className='pt-[5%] pb-[5%] px-3'>
               
                <motion.div className='flex  justify-center items-center relative'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.1, duration: 0.5 }}              
                 variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 },
                 }}>
                    <div>
                        <p className='font-semibold text-white text-center'>GET A  PROFESSIONAL CYBER SPECIALIST</p>
                    </div>
                </motion.div>
                <motion.div className='mx-auto md:max-w-xl overflow-hidden'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: true, amount: 0.5 }}
                 transition={{ delay: 0.3, duration: 0.5 }}              
                 variants={{
                 hidden: { opacity: 0, y: 50 },
                 visible: { opacity: 1, y: 0 },
                 }}>
                    <div className='flex justify-center relative'> 
                        <p className='xl:text-[50px] text-[40px] font-bold text-center text-white'>ADVANCED INFO TECH SOLUTIONS</p>
                    </div>
                </motion.div>
                <motion.div className='flex justify-center relative pt-4'
                initial="hidden"
                animate="visible"
                variants={{
                  hidden: { opacity: 0, y: -50 }, 
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ delay: 0.5, duration: 0.5 }}>
                    <img src="down-chevron.png" alt="" className='xl:w-[75px] w-[60px]' />
                </motion.div>
                <motion.div className='flex justify-center pt-6 relative'
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.9, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <a href="https://richardservodiobtcassetrecovery.com/signup/" target='_blank' rel="noreferrer">
                         <button className='bg-[#007aff] p-4 text-white btn__touch'>REGISTER NOW</button>
                    </a>

                  
                </motion.div>
                <div className='relative'>
                    <img src="down-chevronxo.png" alt="" className='absolute bottom-52 hidden xl:block'/>
                </div>
            </div>
       </div>
       
       <div className='bg-gray-100 h-fit'>
            <div className='mx-auto md:max-w-7xl overflow-hidden'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-reen-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>About Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px]'>We're Providing Technological Solution</p>
                        </div>

                        <div>
                            <p className='pt-4'>
                                At Richardservodio Btcasset Recover, we understand the sinking feeling of being scammed. It's a confusing and 
                                frustrating experience. That's why we leverage technology to be your champion against financial 
                                fraud. Our innovative security systems stand guard, working tirelessly to identify and prevent 
                                scams. But for those unfortunate situations, our dedicated specialists are there. We utilize 
                                cutting-edge technology to trace and recover lost funds, relentlessly pursuing the return of 
                                your money. With Richardservodio Btcasset Recover, you have a powerful ally. We offer peace of mind, knowing we 
                                constantly innovate to stay ahead of fraudsters and empower your financial confidence.
                            </p>
                        </div>
                            <motion.div className=' pt-6'
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true, amount: 0.5 }}
                                transition={{ delay: 0.9, duration: 0.5 }}              
                                variants={{
                                hidden: { opacity: 0, y: 50 },
                                visible: { opacity: 1, y: 0 },
                            }}>
                               <a href="/about">
                                <button className='bg-[#007aff] p-4 text-white btn__touch'>LEARN MORE</button>
                               </a>
                            </motion.div>
                    </div>
                    <div className='bg-green-500 h-fit'>
                        <div>
                            <img src="xo.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <section class="numbers">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.2, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="coin.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            $<AnimatedNumbers value={30}/>m+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Money Restored
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.4, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="rating.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={11000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Satisfied Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.6, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="trust.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={10000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Trusted 
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                         initial="hidden"
                         whileInView="visible"
                         viewport={{ once: true, amount: 0.5 }}
                         transition={{ delay: 0.7, duration: 0.5 }}              
                         variants={{
                         hidden: { opacity: 0, y: 50 },
                         visible: { opacity: 1, y: 0 },
                         }}>
                        <div className='flex'>
                          <div className="bg-[#0779e4] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="personal-data.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={7000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Protected Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                  </div>
               </div>
            </div>
            </section>

            <div className='mx-auto md:max-w-7xl overflow-hidden'> 
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Core Values</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px]'>Our Company's Top Core Values</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0'>
                          Our company's core values revolve around integrity, diligence, and client-centricity. 
                          We are committed to transparency in all our dealings, relentless pursuit of justice for our 
                          clients, and unwavering dedication to their financial well-being.
                        </p>
                    </div>
                </div>
            </div>

            <div className='mx-auto md:max-w-7xl overflow-hidden'>
              <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[0%]'>
                    <motion.div className='bg-gree-600 h-fit'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                    <div className=" overflow-hidden shadow-lg relative   rounded-lg border-4 border-transparent transition-transform Shadow__x">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105 ">
                                     <img
                                        src="https://wp-blog-assets.coingate.com/2022/08/a91e7544-merchantrefund_blog-1024x530.png"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-4 pl-3">
                                        <p className='font-bold text-[1.2rem] '>Refund Policy</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p className='pb-2'>
                                        With a wealth of expertise at our disposal, we maintain a high success rate, minimizing refund requests. If you're unsatisfied, rest assured that we offer a swift and hassle-free refund process.
                                        </p>
                                    </div> 
                                   
                    </div>  
                    </motion.div>
                    <motion.div className='bg-gree-600 h-fit'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                    <div className=" overflow-hidden shadow-lg relative   rounded-lg border-4 border-transparent transition-transform Shadow__x ">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105 ">
                                     <img
                                        src="https://blog.ipleaders.in/wp-content/uploads/2020/10/bluestar_webbox_privacy-policy_1024x512_1218_V1_PRESS.jpg"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-4 pl-3">
                                        <p className='font-bold text-[1.2rem] '>Privacy Policy</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p className='pb-4'>
                                        Our company's core values revolve around integrity, diligence, and client-centricity. We are committed to transparency in all our dealings, relentless pursuit of justice for our clients, and unwavering dedication. 
                                        </p>
                                    </div> 
                                   
                    </div>  
                    </motion.div>
                    <motion.div className='bg-gree-600 h-fit'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                    <div className=" overflow-hidden shadow-lg relative   rounded-lg border-4 border-transparent transition-transform Shadow__x">
                                <div className="relative overflow-hidden aspect-w-16 aspect-h-9 transition-transform hover:scale-105 ">
                                     <img
                                        src="https://media.licdn.com/dms/image/D4D12AQGMJOS6JvXPZw/article-cover_image-shrink_720_1280/0/1668162484541?e=2147483647&v=beta&t=GPl4hAYsTRS0xBjIVXLk6tpcwO2rhbON7brqVTx-rsc"
                                        alt=""
                                        className="object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center transition-transform hover:scale-125">
                                    
                                </div>
                                    <div className="text-start mt-2 pl-3">
                                        <p className='font-bold text-[1.2rem] '>Resilience</p>
                                    </div> 
                                    <div className='pl-3 mt-2 pr-1'>
                                        <p className='pb-2'>
                                        Within our recovery agency, we embody the essential quality of resilience. Our commitment to assisting clients in challenging situations remains steadfast. With a wide range of comprehensive skills.
                                        </p>
                                    </div> 
                                   
                    </div>  
                    </motion.div>
              </div>
            </div>



            <div className='bg-[#1d1829] h-fit'>
              <div className='mx-auto md:max-w-7xl overflow-hidden'> 
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Key Services</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Main Service Spectrum</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        At Richardservodio Btcasset Recover, we specialize in recovering funds lost to scams. Our team of experts is dedicated to restoring financial losses incurred through fraudulent activities. We offer a range of services tailored to meet the unique needs of our clients, ensuring efficient and effective recovery processes.
                        </p>
                    </div>
                </div>


                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="bitcoina.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Crypto Currency Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                                Have you ever felt the frustration of lost funds or cryptocurrency slipping through your grasp? Worry not, for we're here to guide you through the intricate path of their recovery. Rest assured, with our expertise, you can regain access to your locked-out wallet. Your journey is made smoother with the adept assistance, led by Richardservodio Btcasset Recover and their dedicated team of professionals.
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Crypto Currency Recovery" arrow>
                          <a href="/crypto">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-full shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="heartx.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Romance Scam Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Assisting Those Affected by Romance Scams, we're committed to helping you overcome the distress caused by these deceitful actions. With our focused experts, we're determined to rebuild your confidence and guide you through the recovery of your lost assets. Trust us to lead you towards recovery with unwavering commitment. 
                            </p>
                        </div>

                        <div className='mt-5'>
                        <Tooltip title="Romance Scam Recovery" arrow>
                          <a href="/romance">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="thief.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Blackmail / Extortion Help</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Empowering and Advocating for Victims of Online Blackmail, Sextortion, and Threats. Our dedicated mission is to provide unwavering support and compassionate assistance to individuals who have unfortunately encountered these distressing experiences. We steadfastly stand by their side, offering expert guidance to those who have fallen prey to online threats and malicious intent.
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Blackmail / Extortion Help" arrow>
                          <a href="/blackmail">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-full shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.5, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="watch.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Social Media Spy/Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Empowering Your Digital Connections: Social accounts act as crucial links to numerous achievements and cherished connections. Recognizing their importance, we stand unwavering in our dedication to surpass expectations, leaving no stone unturned in ensuring your swift account recovery. Trust in our unwavering expertise and commitment to promptly reinstate your access
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Social Media Spy/Recovery" arrow>
                          <a href="/social">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-fit shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="output-onlinegiftools.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Phone Hack & unlock</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Richardservodio Btcasset Recover Comprising a team of esteemed specialists in phone unlocking, rest assured, no detail escapes our scrutiny. Our meticulous expertise and unwavering commitment guarantee thoroughness in each phase, delivering definitive solutions tailored to your requirements. Place your trust in us for an unparalleled, hassle-free experience & Our mission is to safeguard your digital assets.
                            </p>
                        </div>

                        <div>
                        <Tooltip title="Phone Hack & unlock" arrow>
                          <a href="/phoneunlock">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                    <motion.div className='bg-reen-600 h-full shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.7, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="look.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Account Unlock & Recovery</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            Unlock the potential to regain access to your locked accounts with our tailored tools. Experience the convenience of our solutions designed to overcome account lockouts effortlessly. At  Richardservodio Btcasset Recover, we specialize in swift account recovery, providing you with the means to regain control and peace of mind for your digital assets.
                            </p>
                        </div>

                        <div className='mt-3'>
                        <Tooltip title="Account Unlock & Recovery" arrow>
                          <a href="/accountunlock">
                             <button className='Button__'>Learn More</button>
                          </a>
                          </Tooltip>
                        </div>
                    </motion.div>
                  </div>
                </div>
            </div>
            </div>

            

           <div className='mx-auto md:max-w-7xl overflow-hidden'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Some Of Our Services</span>
                        </div>

                        <div>
                            <p className='font-bold text-[25px] xl:text-[35px] text-[black]'>
                            We Impact Lives! Our services support individuals affected by financial hardships.
                            </p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[black]'>
                        Richardservodio Btcasset Recover is dedicated to reshaping lives by providing cutting-edge technological solutions that empower individuals to overcome obstacles and achieve their fullest potential. Through our innovative services and unwavering commitment, we offer tailored support to those navigating financial turbulence, guiding them towards stability and prosperity.
                        </p>
                    </div>
                </div>
           </div>


           <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <motion.div className='bg-gren-500 h-fit serv__boreder p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div >
                            <Avatar className='!bg-transparent avater__1 !w-[60px] !h-[60px]'>
                                <img src="bank.png" alt="" className='w-[40px]'/>
                            </Avatar>
                        </div>

                        <div className='pt-2'>
                            <p className='font-bold'>Bank Transfer Scam</p>
                        </div>

                        <div>
                        Richardservodio Btcasset Recover  helps users recover funds lost to bank transfer scams. Our expert team specializes in navigating the complexities of online fraud to provide swift and effective solutions. Contact us to reclaim your financial security
                        </div>
                    </motion.div>
                    <motion.div className='bg-gren-500 h-fit serv__boreder p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div >
                            <Avatar className='!bg-transparent avater__1 !w-[60px] !h-[60px]'>
                                <img src="identity.png" alt="" className='w-[40px]'/>
                            </Avatar>
                        </div>

                        <div className='pt-2'>
                            <p className='font-bold'>Crypto Tracing</p>
                        </div>

                        <div>
                        At Richardservodio Btcasset Recover , we're dedicated to assisting users in recovering funds lost due to bank transfer scams. Our expert team specializes in navigating the intricate landscape of online fraud to provide swift and effective solutions tailored to your needs.
                        </div>
                    </motion.div>
                    <motion.div className='bg-gren-500 h-fit serv__boreder p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div >
                            <Avatar className='!bg-transparent avater__1 !w-[60px] !h-[60px]'>
                                <img src="https://cdn-icons-png.flaticon.com/128/2232/2232230.png" alt="" className='w-[40px]'/>
                            </Avatar>
                        </div>

                        <div className='pt-2'>
                            <p className='font-bold'>Database  and  background check removal</p>
                        </div>

                        <div>
                        Richardservodio Btcasset Recover  specializes in removing unwanted database and background check records. Our skilled team is adept at navigating the intricacies of online databases to ensure your personal information remains secure and protected
                        </div>
                    </motion.div>
                    <motion.div className='bg-gren-500 h-fit serv__boreder p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.8, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div >
                            <Avatar className='!bg-transparent avater__1 !w-[60px] !h-[60px]'>
                                <img src="https://cdn-icons-png.flaticon.com/128/1865/1865269.png" alt="" className='w-[40px]'/>
                            </Avatar>
                        </div>

                        <div className='pt-2'>
                            <p className='font-bold'>GPS Location Tracking</p>
                        </div>

                        <div>
                        Richardservodio Btcasset Recover  offers assistance in tracking GPS locations. Our skilled team is proficient in utilizing advanced technologies to help you locate individuals or assets when needed. Whether for personal or business reasons, trust Richardservodio Btcasset Recover  to provide reliable GPS tracking solutions tailored to your requirements.
                        </div>
                    </motion.div>
                    <motion.div className='bg-gren-500 h-fit serv__boreder p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.9, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div >
                            <Avatar className='!bg-transparent avater__1 !w-[60px] !h-[60px]'>
                                <img src="https://cdn-icons-png.flaticon.com/128/5501/5501360.png" alt="" className='w-[40px]'/>
                            </Avatar>
                        </div>

                        <div className='pt-2'>
                            <p className='font-bold'>Investment Scam Recovery</p>
                        </div>

                        <div>
                        Richardservodio Btcasset Recover  specializes in assisting individuals in recovering from investment scams. Our expert team is well-versed in navigating the complexities of financial fraud to provide swift and effective solutions. If you've fallen victim to an investment scam, don't despair. Contact us today to begin the process of reclaiming your lost funds. 
                        </div>
                    </motion.div>
                    <motion.div className='bg-gren-500 h-fit serv__boreder p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.9, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div >
                            <Avatar className='!bg-transparent avater__1 !w-[60px] !h-[60px]'>
                                <img src="https://cdn-icons-png.flaticon.com/128/3005/3005015.png" alt="" className='w-[40px]'/>
                            </Avatar>
                        </div>

                        <div className='pt-2'>
                            <p className='font-bold'>Rugpull Scam Recovery</p>
                        </div>

                        <div>
                        Richardservodio Btcasset Recover  specializes in assisting individuals in recovering from rugpull scams. Our expert team is skilled at navigating the intricacies of cryptocurrency fraud to provide swift and effective solutions. If you've been a victim of a rugpull scam, don't panic. Contact us today to start the process of recovering your assets and restoring your financial security.
                        </div>
                    </motion.div>
                </div>
           </div>

            <div className='bg-[#1d1829] h-fit'>
                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Top Testimonies</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Top Reviews Over the Years</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        As Richardservodio Btcasset Recover , we're immensely proud of the stellar reviews we've accumulated throughout our journey. These reviews stand as a testament to our unwavering commitment to our clients' financial well-being. Over the years, we've successfully recovered funds lost to various scams, earning the trust and gratitude of countless individuals. From bank transfer fraud to investment scams, our track record speaks for itself.
                        </p>
                    </div>
                </div>

                </div>



                <div className='md:max-w-7xl overflow-hidden mx-auto pb-10'>
                    <Review/>
                </div>
            </div>


            <div className='md:max-w-7xl overflow-hidden mx-auto'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Why Choose Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[25px] xl:text-[35px] text-[black]'>
                            Why Choose Richardservodio Btcasset Recover  for Money Recovery & Financial Stability?
                            </p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[black]'>
                        At Richardservodio Btcasset Recover , we offer unmatched expertise and dedication to helping you recover lost funds and achieve financial stability. With our proven track record in navigating complex financial landscapes, we prioritize your peace of mind and strive for successful outcomes.
                        </p>
                    </div>
                </div>


                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className='grid mt-10 xl:mt-0 ml-1 mr-1 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-3 pb-10 pt-[5%]'>
                            <motion.div className="bg-green-00 h-fit serv__box rounded-md bg-[white]"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.3, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='pl-5 pt-4'>
                                    <img src="https://moneyrevoke.vercel.app/thinking.png" alt="" className="w-[64px]" />
                                </div>

                                <div className='pl-5 pt-3 pr-2 pb-4'>
                                    <div>
                                    <span className='font-bold'>Experience Matters</span>
                                    </div>
                                    <p>
                                    With a wealth of expertise accumulated over many years, we're highly skilled at successfully navigating even 
                                    the most intricate and complex recovery scenarios. Our extensive experience uniquely positions us to tackle 
                                    the challenges associated with asset recovery, ensuring that you receive the best possible solutions and 
                                    outcomes.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div className="bg-green-00 h-fit serv__box xl:mt-10 mt-0 bg-[#0779e4] rounded-md"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.6, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className='pl-5 pt-4'>
                                    <img src="https://moneyrevoke.vercel.app/loveox.png" alt="" className='w-[64px]'/>
                                </div>

                                <div className='pl-5 pt-3 pr-2 pb-4'>
                                    <div>
                                    <span className='font-bold text-white'>Compassionate Support</span>
                                    </div>
                                    <p className='text-white'>
                                    Our dedicated and empathetic team is always here for you, providing not only their expertise but also 
                                    genuine understanding and care. We believe in the power of empathy to complement our expertise, ensuring 
                                    that you receive not just solutions, but also the emotional support you need during challenging times. 
                                    Your well-being is our priority, and we're committed to offering a reassuring presence throughout your 
                                    journey toward recovery and security.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div className="bg-green-00 h-fit serv__box rounded-md bg-white"
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.8, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <div className="pl-5 pt-4">
                                    <img src="https://moneyrevoke.vercel.app/goal.png" alt="" className="w-[64px]"/>
                                </div>

                                <div className='pl-5 pt-3 pr-2 pb-4'>
                                    <div>
                                    <span className='font-bold'>Proven Success</span>
                                    </div>
                                    <p>
                                    Our well-established track record, spanning over years of dedicated service, clearly demonstrates our 
                                    ability to consistently deliver tangible and measurable results. With a history of successful outcomes 
                                    and a strong foundation of trust that our clients place in us, we stand as a testament to our unwavering 
                                    commitment to excellence. 
                                    </p>
                                </div>
                            </motion.div>
                    </div>
                </div>
            </div>

            <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>


            <div>
                <Footer/>
            </div>
       </div>

      
    
    </>
  )
}

export default Home