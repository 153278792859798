import React, { useState } from 'react';

const BusinessFooter = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage('Please enter your email.');
    } else {
      setIsSubscribed(true);
      setErrorMessage('');
      setEmail('');
    }
  };

  return (
    <footer className="bg-black text-white py-4 px-2">
      <div className="max-w-6xl mx-auto flex flex-wrap">
        <div className="w-full md:w-1/3">
          <h3 className="text-xl font-semibold mb-4">Contact</h3>
          <p>Email: info@richardservodiobtcassetrecovery.com</p>
          {/* <p>Phone: (123) 456-7890</p> */}
          <p>Address: South Dakota</p>
        </div>
        <div className="w-full md:w-1/3">
          <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
          <div className="flex space-x-4">
        
            <a href="mailto:info@richardservodiobtcassetrecovery.com" className="text-white hover:text-gray-500"
            target="_blank"
            rel="noopener noreferrer"
            >
            <i class="ri-mail-open-line text-[1.3rem]"></i>
            </a>

           
          

            <a href="wa.me/14692151086" className='hover:text-gray-500'
                target="_blank"
                rel="noopener noreferrer"
            >
              <i class="ri-whatsapp-line text-[1.3rem]"></i>
            </a>
          </div>
          <h3 className="text-xl font-semibold mb-4">Call: +14692151086</h3>
        </div>
        <div className="w-full md:w-1/3">
          <h3 className="text-xl font-semibold mb-4">Subscribe</h3>
          {isSubscribed ? (
            <p className="text-green-500">Thank you for subscribing!</p>
          ) : (
            <form onSubmit={handleSubmit} className="mt-4 flex items-center">
              <input
                type="email"
                className="px-4 py-2 w-full rounded-l-lg focus:outline-none text-black"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" className="px-4 py-2 bg-[#0779e4] text-white rounded-r-lg">
                Subscribe
              </button>
            </form>
          )}
          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
        </div>
      </div>
      <div className="max-w-6xl mx-auto mt-8 text-center">
        <p className="text-sm">&copy; 2010 Richardservodio Btcasset Recover All rights reserved.</p>
        <p className="text-sm">Terms of Service | Privacy Policy</p>
      </div>
    </footer>
  );
};

export default BusinessFooter;
