import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; 
import { Avatar } from '@mui/material';

const MySwiper = () => {
  
  const customColorStyle = {
    color: 'yellow',
  };
  
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
        <div className='border__b  mb-5 rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://preview.redd.it/7un2oijtdxy41.jpg?auto=webp&s=99a06a99e27ef7191d141dceef231face043e3d9' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Thaddeus Lockwood</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='px-2'>
            <p className='text-[whitesmoke] text-center pt-[10%] pb-5 '>
                    Over an extended period, I discovered myself trapped in the clutches of a fraudulent crypto trading platform. I 
                    entrusted my earned capital, envisioning substantial profits. My savings grew to $100,000, but optimism turned to 
                    disappointment as phantom gains filled my account. My frustration intensified when attempting a withdrawal validated 
                    my suspicions—withdrawal was an impossible endeavor.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-3'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-5'>Atlanta, GA USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5 mb-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://pbs.twimg.com/media/Fy3l2U4X0AEJePb.jpg:large' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Seraphina Montgomery</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className=''>
            <p className='text-[whitesmoke] text-center pt-5 pb-5'>
            In an unexpected turn, I was ensnared by a romantic deception woven by an imposter posing as a military officer. 
            Convinced to lend $80,000, I awaited a $120,000 return. Regrettably, the scammer disappeared, leaving me financially 
            devastated and grappling with profound betrayal.
            Amid my anguish, fortune favored me when I found  Richardservodio Btcasset Recover, a beacon of hope during my darkest hours. Their rapid 
            expertise not only recovered my funds but also revealed the deception. 
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Idaho, USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.pinimg.com/736x/79/61/a9/7961a9b27e47f492660bf15c579dae5a.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Calista Sinclair</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='p-1'>
            <p className='text-[whitesmoke] text-center pt-2 pb-2'>
                    I was in the process of recovering my losses through a supposed recovery firm, but they took a substantial amount of 
                    money from me while promising to retrieve my lost funds. Fortunately, my path crossed with a savior, Richardservodio Btcasset Recover
                    , who helped me reclaim my money from the fraudulent recovery firm and successfully restored my funds from the 
                    scammers. Jame's expertise turned the tables on those who had taken advantage of me, and I am immensely grateful for 
                    he's assistance in rectifying this situation.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[9%]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Baltimore, GA USA 🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://i.pinimg.com/1200x/1c/e3/9c/1ce39c758dcc18d142743f54771617c2.jpg'alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Leander Hawthorne</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div className='py-1'>
            <p className='text-[whitesmoke] text-center pt-[5%] pb-5'>
            In an unfortunate turn, I mistakenly shared my bank details online, resulting in a swift depletion of over $80,000 from my
            account. As the situation worsened with the threat of potential loans, I came across  Richardservodio Btcasset Recover's recommendation. His 
            expertise proved invaluable - not only did he recover my funds but also brought the scammer to justice, turning the 
            tide in my favor.  I am immensely grateful for Elias's expertise, which not only rectified the financial damage but
            also brought justice to the situation.
               </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[10%]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400 pt-[1%]'>Manitoba, Canada 🇨🇦</p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src="https://nevadanewsgroup.media.clients.ellingtoncms.com/img/photos/2023/08/19/colreflections-rcr-0801923_t670.jpeg?b3f6a5d7692ccc373d56e40cf708e3fa67d9af9d" alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Osias Winchester</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[whitesmoke] text-center pt-5 pb-5'>
            Caught in a distressing situation, I found myself blackmailed by someone exploiting my past hardships. This online 
                    extortionist threatened harm to me and my family, leveraging my vulnerabilities. For years, they extracted significant 
                    sums of money from me, leaving me trapped in a cycle of payment. However, a turning point arrived when I was introduced 
                    to  Richardservodio Btcasset Recover.
                    Richardservodio Btcasset Recover's intervention proved pivotal; he not only put an end to the blackmail but also ensured that the perpetrator
                    faced legal consequences.
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Memphis, USA  🇺🇸 </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='border__b rounded-md'>
          <div className='flex justify-center pt-5'>
              <Avatar className='!w-[50px] !h-[50px] '>
                <img src='https://photos.spareroom.com/images/flatshare/listings/large/20/43/204368230.jpg' alt=""/>
              </Avatar>
          </div>
          <div className='flex justify-center'> 
            <p className='text-[whitesmoke] font-bold pt-2'>Percival Beauregard</p>
          </div>
          <div className='flex justify-center'>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          <ion-icon name="star-outline" style={customColorStyle}></ion-icon>
          </div>

          <div>
            <p className='text-[whitesmoke] text-center pt-5 pb-5'>
            Ensnared by a romance scammer who exploited my emotions, I found myself sacrificing everything to fulfill his demands. 
                    I sold my properties and even my car, all in an attempt to please him. It was a heart-wrenching realization when I 
                    discovered he was fake.
                    A friend's recommendation introduced me to  Richardservodio Btcasset Recover, a true savior. With expertise, he unveiled the scammer's 
                    identity, recovered my money, and restored my properties.  Richardservodio Btcasset Recover's actions turned my life around, freeing me 
                    from deception. 
            </p>
          </div>
          <div className='flex justify-center text-center'>
           <div className='pt-[1px]'>
           <ion-icon name="location-outline" size="medium" color="primary"></ion-icon>
           </div>
            <p className='text-gray-400'>Wales, United Kingdom 🇬🇧 </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
