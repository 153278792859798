import React from 'react';
import Navbars from '../hooks/Navbars';
import { GoDash } from "react-icons/go";
import Footer from "../hooks/Footer";
import { Link } from "react-router-dom";
import SocialReview from '../hooks/SocialReview.jsx';


const Crypto = () => {
  const CustomeIcon = {
    fontSize: "25px",
    '--custom-icon-color': '#0e6af4'
}
  return (
   <>
    <div className=''>
      <Navbars/>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl'>
      <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[1%]'>
        <div className='bg-gren-600 h-fit'>
            <div className='xl:pt-10 pt-0'>
              <img src="https://img.freepik.com/free-photo/front-view-woman-holding-smartphone_23-2150208244.jpg?t=st=1715559390~exp=1715562990~hmac=12d941a045d1af8ad78f18ee691aa8acef5a6e39612035f61e5c7a69922250a2&w=996" alt="" />
            </div>
        </div>
          <div className='bg-gren-500 h-fit xl:pl-5 pl-1'>
            <div className='flex'>
                <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4] uppercase'>Services</span>
            </div>

            <div>
                <p className='font-bold text-[30px] xl:text-[40px] text-[black] uppercase'>Social Media Hack/Recovery</p>
            </div>

            <div className='pt-5'>
              <p className='font-bold text-[#0e6af4]'>Richardservodio Btcasset Recover, Your Trusted Ally For Social Media Spy and Recovery</p>
            </div>

            <div className='pt-5'>
              <p>Social media, a dynamic term encompassing computer-based technology, serves as a vibrant platform facilitating the seamless exchange of ideas, thoughts, and information across virtual networks and communication channels. This internet-based phenomenon empowers users with swift electronic communication capabilities, enabling the dissemination of diverse content ranging from personal anecdotes to multimedia assets like documents, videos, and photos. Whether accessed via web-based software or intuitive applications on computers, tablets, or smartphones, social media has become an integral part of modern connectivity.</p>
            </div>
            <div className='pt-6'>
              <p>
              While its prevalence is widespread across America, European, and Asian territories, countries like Indonesia lead the pack in social media engagement. Remarkably, over 4.5 billion individuals actively utilize social media as of October 2022, underscoring its pervasive influence on global communication landscapes.
              </p>
            </div>
            <div className='pt-5'>
              <p>
                Enter Richardservodio Btcasset Recover, a distinguished authority in the realm of cybersecurity, specializing in the provision of  hacking and recovery services tailored for various social media platforms. From safeguarding Facebook and WhatsApp accounts to securing Instagram, Snapchat, TikTok, Messenger, Gmail, Telegram, WeChat, Discord, and beyond, Richardservodio Btcasset Recover offers comprehensive solutions to protect and restore your digital presence with unparalleled expertise and integrity.
              </p>
            </div>
          </div>
      </div>
    </div>
    <div className='bg-[#0E6AF4] w-full h-fit p-4 overflow-hidden'>
        <div className='mx-auto md:max-w-4xl overflow-hidden'>
            <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden'>
                <div className='overflow-hidden'>
                 <div className='flex'>
                 <img src="shield.png" alt="" className='w-[64px]'/>
                  <div className='pl-5'>
                    <p className='font-bold text-white pt-3'>Stop worrying, our recovery process is guaranteed.</p>
                  </div>
                 </div>
                </div>
                <div>
                 <div className='xl:ml-[15%] ml-1'>
                   <a href="/contact">
                    <button className='bg-[#1d1829] p-4 text-white btn__touch1'>GET IN TOUCH</button>
                   </a>
                 </div>
                </div>
            </div>
        </div>
    </div>
          <div className='bg-[#1d1829] h-fit'>
             <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Top Testimonies</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Top Reviews on Social Media Hack & Recovery.</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        Unlocking the Power of Social Media: With billions of users worldwide, social media has revolutionized communication. Richardservodio Btcasset Recover specializes in ethical hacking and recovery services for major platforms like Facebook, WhatsApp, Instagram, and Snapchat, ensuring your digital presence remains secure.
                        </p>
                      </div>
                      </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden pb-10'>
                  <SocialReview/>
                </div>
            </div>

        <div className=''>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Why Choose Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[black]'>Why Choose Richardservodio Btcasset Recover For Social Media Hack & Recovery</p>
                        </div>
                        <div className='pt-5 pr-3 pb-5 xl:pb-0'>
                          <p>Richardservodio Btcasset Recover distinguishes itself as the foremost option for social media hacking and recovery needs. With unparalleled expertise and dedication, we ensure the safety and restoration of your digital presence. Choose Richardservodio Btcasset Recover for trusted and reliable solutions.</p>
                        </div>

                        <div className='bg-geen-600 h-fit mt-0 xl:mt-10'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>All Social Media Hack/Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Text Messages Hack/Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Suspended Social Media Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Whatsapp Spy</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Gmail Hack/Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Text Messages Hack/Spy</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Gmail Hack/Spy</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Sim Card Cloning</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                               
                        </div>
                            
                    </div>
                    <div className='bg-gren-500 h-fit'>
                      <div className='shadow-md xl:mt-10 mt-0'>
                        <img src="https://img.freepik.com/free-photo/female-hacker-with-her-team-cyber-terrorists-making-dangerous-virus-attack-government_482257-23001.jpg?t=st=1715560464~exp=1715564064~hmac=99e3bac1f07d69ea52f5db841713a1d5cbe4192c9c7c7ca233ff0897adc986a5&w=1480" alt=""  className='rounded-md shadow-md img__border'/>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>

      <Footer/>
   </>
  )
}

export default Crypto;